<template>
  <div>
    <ValidationProvider tag="div" :name="textareaName" :rules="rules">
      <label
        v-if="inputLabel"
        :for="textareaName"
        class="mb-10 block font-semibold cursor-pointer"
      >{{ inputLabel }} <span v-if="required || required === ''" class="text-red-100">*</span></label>

      <div class="relative">
        <textarea
          ref="textarea"
          class="el-input w-full border border-grey-200 px-12 py-8 outline-none text-base sm:text-sm leading-big sm:leading-normal focus:border-blue focus:shadow-outline-blue"
          :value="value"
          autocomplete="off"
          :name="textareaName"
          v-bind="$attrs"
          :type="$attrs.type"
          :class="[textareaClass, isDisabled]"
          v-on="{ ...$listeners, input: e => $emit('input', e.target.value) }"
        />
        <p v-if="description" class="mt-4 text-sm text-grey-400">{{ description }}</p>
        <slot name="description" />
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: 'ElTextarea',

  props: {
    value: {
      type: [Number, String],
      default: ''
    },

    textareaClass: { type: String, default: '' },

    textareaName: { type: String, default: '' },

    rules: { type: [String, Object], default: '' },

    inputLabel: { type: String, default: '' },

    required: {
      type: [String, Boolean],
      default: false
    },

    description: String

  },

  computed: {
    isDisabled () {
      return this.$attrs.disabled ? 'cursor-not-allowed bg-grey-100' : ''
    }
  },

  mounted () {
    if (this.$refs.textarea && this.textareaName) {
      this.$refs.textarea.id = this.textareaName
    }
  }
}
</script>

<style lang="scss">
  .el-input {
    &::placeholder {
      color: #6e7583;
    }
    :-ms-input-placeholder {
      color: #6e7583;
    }
    ::-ms-input-placeholder {
      color: #6e7583;
    }
  }
</style>
